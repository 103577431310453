@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#root {
  height: inherit;
}

